import React from 'react'

const status = {
  active: 'limegreen',
  away: 'orange',
  busy: 'red',
  offline: 'grey',
  default: 'grey'
}

function ActivityBadge (props) {
  return (
    <svg height={props.size || '100%'} width={props.size || '100%'} viewBox='0 0 100 100'>
      <circle cx='50' cy='50' r='40' fill={status[props.status] || status.default} />
    </svg>
  )
}

export default ActivityBadge
