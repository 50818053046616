import React, { Component } from 'react'
import './App.css'
import TopBar from './locations/TopBar'
import Background from './locations/Background'
import SideBar from './locations/SideBar'
import { Switch, Route } from 'react-router-dom'

export class App extends Component {
  render () {
    return (
      <Switch>
        <Route exact path='/background' component={Background} />
        <Route exact path='/topbar' component={TopBar} />
        <Route exact path='/sidebar' component={SideBar} />
      </Switch>
    )
  }
}

export default App
