import { db } from './Firebase'

class Activity {
  constructor (accountId, userId, object, referenceId, type) {
    this.id = null
    this.accountId = accountId
    this.userId = userId
    this.object = object // ticket, user, org
    this.referenceId = referenceId // objectId || null if new and incomplete
    this.type = referenceId ? type : 'create' // view, update, create
    this.startTime = new Date().toISOString()
    this.endTime = null
  }

  add () {
    addOrUpdate(this).then(data => { this.id = data.id })
  }

  update () {
    return addOrUpdate(this)
  }

  end () {
    this.endTime = new Date().toISOString()
    return addOrUpdate(this)
  }
}

function addOrUpdate (activity) {
  const batch = db.batch() // initialize batch

  let activityRef
  let activityData

  if (activity.id) {
    activityRef = db.collection('accounts').doc(activity.accountId).collection('users').doc(activity.userId).collection('activities').doc(activity.id)
    activityData = { ...activity }
  } else {
    activityRef = db.collection('accounts').doc(activity.accountId).collection('users').doc(activity.userId).collection('activities').doc()
    activityData = {
      ...activity,
      id: activityRef.id
    }
  }
  batch.set(activityRef, activityData)

  const userRef = db.collection('accounts').doc(activity.accountId).collection('users').doc(activity.userId)
  batch.update(userRef, { lastActivity: activityData })

  return batch.commit().then(() => activityData)
}

export default Activity
