/* global ZAFClient */
const Client = ZAFClient.init()

export const getData = (object) => {
  return Client.get(object).then(data => data[object])
}

export const getContext = () => {
  return Client.context().then(data => data)
}

export default Client
