import { db } from './Firebase'
import { getData } from './Client'
import { Observable } from 'rxjs'

const User = {}

User.get = async () => {
  const subdomain = await getData('currentAccount.subdomain')
  const id = await getData('currentUser.id') + ''

  return new Promise((resolve, reject) => {
    db.collection('accounts').doc(subdomain).collection('users').doc(id).get()
      .then(doc => {
        if (doc.exists) {
          resolve(doc.data())
        } else {
          resolve(null)
        }
      })
  })
}

User.create = async () => {
  const subdomain = await getData('currentAccount.subdomain')
  const currentUser = await getData('currentUser')

  return new Promise((resolve, reject) => {
    const user = {
      id: currentUser.id + '',
      accountId: subdomain,
      name: currentUser.name,
      email: currentUser.email,
      createdAt: new Date().toISOString(),
      status: 'offline',
      photo: currentUser.avatarUrl
    }

    db.collection('accounts').doc(subdomain).collection('users').doc(user.id).set(user)
      .then(() => {
        resolve(user)
      })
      .catch(error => {
        reject(error)
      })
  })
}

User.observe = async () => {
  const subdomain = await getData('currentAccount.subdomain')
  const id = await getData('currentUser.id') + ''

  return new Observable(observer => {
    db.collection('accounts').doc(subdomain).collection('users').doc(id)
      .onSnapshot(doc => {
        observer.next(doc.data())
      })
  })
}

export default User
