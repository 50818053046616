import React, { Component } from 'react'
import Client, { getContext } from '../providers/Client'
import User from '../providers/User'
import Users from '../providers/Users'
import AppHeader from '../components/AppHeader'
import AppBody from '../components/AppBody'
import Loading from '../components/Loading'
import Connection from '../providers/Connection'
import { merge } from 'rxjs'
import Auth from '../providers/Auth'

class TopBar extends Component {
  async componentDidMount () {
    await Auth.login()

    const user = await User.get()
    this.setState({ user: user })

    const context = await getContext()
    const connection$ = await Connection.establish(user, context)
    const user$ = await User.observe()
    Promise.all([connection$, user$])
      .then((promises) => {
        merge(promises[0], promises[1])
          .subscribe((mergeData) => {
            let user
            if (mergeData.lastPresence) {
              user = mergeData
            } else {
              user = this.state.user
              user.lastPresence = mergeData
              user.status = mergeData.status
            }
            Client.set('iconSymbol', user.status)
            this.setState({ user: user })
          })
      })

    Users.observe()
      .then(users$ => {
        users$.subscribe(users => {
          if (users) {
            this.setState({ users: users })
          }
        })
      })
  }

  render () {
    if (this.state && this.state.user) {
      Client.invoke('resize', { width: '280px', height: '400px' })
      return (
        <div className='App'>
          <AppHeader user={this.state.user} />
          <AppBody users={this.state.users} />
        </div>
      )
    } else {
      Client.invoke('resize', { width: '280px', height: '76px' })
      return (
        <Loading />
      )
    }
  }
}

export default TopBar
