import fb, { db } from './Firebase'
import Client, { getData } from './Client'

class Presence {
  constructor (accountId, userId, status, source = 'zendesk') {
    this.id = null
    this.accountId = accountId
    this.userId = userId
    this.status = status || 'active' // active, away, offline
    this.source = source || 'zendesk'
    this.startTime = new Date().toISOString()
  }

  add () {
    return addPresence(this)
  }

  static get (userId, id) {
    return getPresence(userId, id)
  }
}

function isOnline () {
  return new Promise((resolve, reject) => {
    fb.database().ref('.info/connected')
      .on('value', snap => {
        if (snap.val() === true) {
          resolve(true)
        } else {
          resolve(false)
        }
      })
  })
}

async function addPresence (presence) {
  const online = await isOnline()
  if (!online) {
    Client.invoke('notify', 'You are currently offline. Please check your internet connection and try again.', 'error', 10000)
    return false
  }

  const batch = db.batch()

  const presenceRef = db.collection('accounts').doc(presence.accountId).collection('users').doc(presence.userId).collection('presences').doc()
  const presenceData = {
    ...presence,
    id: presenceRef.id
  }
  batch.set(presenceRef, presenceData)

  const userRef = db.collection('accounts').doc(presence.accountId).collection('users').doc(presence.userId)
  const userUpdate = {
    status: presence.status,
    lastPresence: presenceData
  }
  batch.update(userRef, userUpdate)

  return batch.commit().then(() => presenceData)
}

async function getPresence (userId, id) {
  const subdomain = await getData('currentAccount.subdomain')
  return db.collection('accounts').doc(subdomain).collection('users').doc(userId).collection('presences').doc(id).get().then(doc => doc.data())
}

export default Presence
