import React from 'react'
import { Grid, Row, Col } from '@zendeskgarden/react-grid'
import { Inline } from '@zendeskgarden/react-loaders'

function Loading () {
  return (
    <Grid>
      <Row>
        <Col className='u-ta-center u-mt' md={12}>
          <Inline size={70} />
        </Col>
      </Row>
    </Grid>
  )
}

export default Loading
