import React from 'react'
import ActivityBadge from './ActivityBadge'
import { Dropdown, Select, Field, Menu, Item } from '@zendeskgarden/react-dropdowns'
import Presence from '../providers/Presence'

const statusOptions = [
  {
    name: 'Active',
    value: 'active'
  },
  {
    name: 'Away',
    value: 'away'
  },
  {
    name: 'Invisible',
    value: 'offline'
  }
]

function StatusSelect (props) {
  return (
    <Dropdown onSelect={value => addPresence(props.user, value)} className='u-1/1'>
      <Field className='u-1/1'>
        <Select className='u-1/1' isCompact>
          <div>
            <div className='u-display-inline u-pr-sm'>
              <ActivityBadge size='12px' status={props.user.status} />
            </div>
            <div className='u-display-inline'>
              {getStatusName(props.user.status)}
            </div>
          </div>
        </Select>
      </Field>
      <Menu placement='bottom' arrow isCompact>
        {statusOptions.map(option =>
          <Item value={option.value} key={option.value} className='u-pl-sm'>
            <div className='u-display-inline u-pr-sm'>
              <ActivityBadge size='12px' status={option.value} />
            </div>
            <div className='u-display-inline'>
              {option.name}
            </div>
          </Item>
        )}
      </Menu>
    </Dropdown>
  )
}

function addPresence (user, status) {
  return new Presence(user.accountId, user.id, status).add()
}

function getStatusName (status) {
  return statusOptions.find(option => option.value === status).name
}

export default StatusSelect
