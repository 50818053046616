import firebase from 'firebase'
import '@firebase/auth'
import '@firebase/firestore'

const config = {
  apiKey: 'AIzaSyDiJGptCXpb_ArgW1aHj3rmKQ46kw-CdTw',
  authDomain: 'aa-presence.firebaseapp.com',
  databaseURL: 'https://aa-presence.firebaseio.com',
  projectId: 'aa-presence',
  storageBucket: 'aa-presence.appspot.com',
  messagingSenderId: '931950180945',
  appId: '1:931950180945:web:f0f61a86a343faf7655670'
}

const fb = firebase.initializeApp(config)

export const auth = fb.auth()
export const db = fb.firestore()
export default fb
