import { db } from './Firebase'
import { getData } from './Client'
import Auth from './Auth'

const Account = {}

Account.get = async () => {
  const subdomain = await getData('currentAccount.subdomain')

  return new Promise((resolve, reject) => {
    db.collection('accounts').doc(subdomain).get()
      .then(doc => {
        if (doc.exists) {
          resolve(doc.data())
        } else {
          resolve(null)
        }
      })
  })
}

Account.status = async () => {
  const subdomain = await getData('currentAccount.subdomain')

  return new Promise((resolve, reject) => {
    db.collection('accounts').doc(subdomain).get()
      .then(doc => {
        const account = doc.data()
        if (account && !account.active) {
          resolve('inactive')
        } else if (account && account.active) {
          resolve('active')
        } else {
          resolve(null)
        }
      })
  })
}

Account.create = async () => {
  const id = await getData('currentAccount.subdomain')
  const currentUser = await getData('currentUser')
  const authUser = Auth.auth.currentUser

  return new Promise((resolve, reject) => {
    const batch = db.batch()

    const userRef = db.collection('accounts').doc(id).collection('users').doc(currentUser.id + '')
    const user = {
      id: currentUser.id + '',
      authId: authUser.uid,
      accountId: id,
      name: currentUser.name,
      email: currentUser.email,
      createdAt: new Date().toISOString(),
      status: 'offline'
    }
    batch.set(userRef, user)

    const accountRef = db.collection('accounts').doc(id)
    const account = {
      id: id,
      active: true,
      createdAt: new Date().toISOString(),
      createdBy: userRef.id
    }
    batch.set(accountRef, account)

    batch.commit()
      .then(() => {
        resolve(account)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export default Account
