import Client, { getData } from './Client'
import Activity from './Activity'
import { Observable } from 'rxjs'

const Session = {}

Session.start = (context) => {
  switch (context.location) {
    case 'ticket_sidebar':
      return session('ticket', context.ticketId)

    case 'new_ticket_sidebar':
      return session('ticket', null)

    case 'user_sidebar':
      return session('user', context.userId)

    case 'organization_sidebar':
      return session('organization', context.organizationId)

    default:
      return null
  }
}

async function session (object, id) {
  const accountId = await getData('currentAccount.subdomain')
  const userId = await getData('currentUser.id') + ''

  return new Observable(observer => {
    let activity = new Activity(accountId, userId, object, id, 'view')
    activity.add()
    observer.next(activity)

    Client.on('app.activated', () => {
      activity = new Activity(accountId, userId, object, id, 'view')
      activity.add()
      observer.next(activity)
    })

    Client.on('app.deactivated', () => {
      if (activity && activity.object === 'ticket' && activity.type === 'update') {
        activity.type = 'view'
        activity.end()
        activity = null
        observer.next(activity)
      } else if (activity) {
        activity.end()
        activity = null
        observer.next(activity)
      }
    })

    Client.on('ticket.save', () => {
      activity.end()
      activity = null
      observer.next(activity)
    })

    Client.on('*.changed', changes => {
      if (activity && activity.type === 'view') {
        activity.type = 'update'
        activity.update()
        observer.next(activity)
      }
    })
  })
}

export default Session
