import React from 'react'
import styled from 'styled-components'
import { ReactComponent as UserIcon } from '@zendeskgarden/svg-icons/src/26/person.svg'

const status = {
  active: 'limegreen',
  away: 'orange',
  busy: 'red',
  offline: 'grey'
}

const size = {
  large: {
    avatar: '50px',
    status: '14px',
    text: '20px'
  },
  medium: {
    avatar: '40px',
    status: '12px',
    text: '16px'
  },
  small: {
    avatar: '30px',
    status: '10px',
    text: '12px'
  }
}

const Figure = styled.figure`
  display: inline-flex;
  position: relative;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  vertical-align: middle;
  box-sizing: border-box;
  width: ${props => props.size ? size[props.size].avatar : size.medium.avatar};
  height: ${props => props.size ? size[props.size].avatar : size.medium.avatar};
  background-color: #a9a9a9;
  color: white;
  transition: box-shadow 0.25s ease-in-out 0s, color 0.1s ease-in-out 0s;
  margin: 0px;
  border-radius: 50%;
  font-weight: bold;

  & > img {
    box-sizing: inherit;
    vertical-align: bottom;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }

  & > span {
    font-size: ${props => props.size ? size[props.size].text : size.medium.text};
  }

  &:after {
    display: inline-block;
    position: absolute;
    right: -4px;
    bottom: -4px;
    opacity: 1;
    min-width: ${props => props.size ? size[props.size].status : size.medium.status};
    max-width: 2em;
    height: ${props => props.size ? size[props.size].status : size.medium.status};
    text-align: center;
    text-overflow: ellipsis;
    line-height: 12px;
    white-space: nowrap;
    font-size: 0px;
    font-weight: 600;
    content: "";
    box-sizing: content-box !important;
    transition: all 0.25s ease-in-out 0s, color 0s ease 0s;
    border-width: 2px;
    border-style: solid;
    border-color: initial;
    border-image: initial;
    border-radius: 100px;
    padding: 0px;
    overflow: hidden;
    background-color: ${props => props.status ? status[props.status] : status.offline};
  }
`

function Avatar (props) {
  const innerDisplay = () => {
    if (props.img) {
      return <img src={props.img} alt='avatar' />
    } else if (props.text) {
      return <span>{props.text}</span>
    } else {
      return <UserIcon />
    }
  }

  return (
    <Figure status={props.status} size={props.size}>
      {innerDisplay()}
    </Figure>
  )
}

export default Avatar
