import { db } from './Firebase'
import { getData } from './Client'
import { Observable } from 'rxjs'

const Users = {}

Users.observe = async () => {
  const accountId = await getData('currentAccount.subdomain')
  const currentUserId = await getData('currentUser.id') + ''

  return new Observable(observer => {
    db.collection('accounts').doc(accountId).collection('users')
      .onSnapshot(col => {
        const users = []
        col.forEach(doc => {
          const user = doc.data()
          if (user.id !== currentUserId) {
            users.push(user)
          }
        })
        observer.next(users)
      })
  })
}

Users.presence = (context) => {
  switch (context.location) {
    case 'ticket_sidebar':
      return usersPresence('ticket', context.ticketId)

    case 'user_sidebar':
      return usersPresence('user', context.userId)

    case 'organization_sidebar':
      return usersPresence('organization', context.organizationId)

    default:
      return null
  }
}

async function usersPresence (object, id) {
  const accountId = await getData('currentAccount.subdomain')
  const currentUserId = await getData('currentUser.id') + ''

  return new Observable(observer => {
    db.collection('accounts').doc(accountId).collection('users').where('lastActivity.object', '==', object).where('lastActivity.referenceId', '==', id)
      .onSnapshot(col => {
        const users = []
        col.forEach(doc => {
          const user = doc.data()
          if (user.id !== currentUserId && !user.lastActivity.endTime && user.status !== 'offline') {
            users.push(user)
          }
        })
        observer.next(users)
      })
  })
}

export default Users
