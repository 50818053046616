import React from 'react'
import { Grid, Row, Col } from '@zendeskgarden/react-grid'
import UserItem from '../components/UserItem'

function AppBody (props) {
  if (props.users) {
    return (
      <div className='AppBody'>
        <Grid gutters={false}>
          <Row>
            <div className='u-semibold u-pl-sm u-mt-sm u-mb-sm'>
              <div>People</div>
            </div>
          </Row>
          {props.users.map(user =>
            <Row key={user.id}>
              <Col style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                <UserItem user={user} />
              </Col>
            </Row>
          )}
        </Grid>
      </div>
    )
  } else {
    return null
  }
}

export default AppBody
