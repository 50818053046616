import { auth } from './Firebase'
import { Observable } from 'rxjs'
import { getData } from './Client'

const Auth = {}

Auth.auth = auth

Auth.login = async () => {
  const accountId = await getData('currentAccount.subdomain')
  const userId = await getData('currentUser.id')
  const email = `${userId}@${accountId}.zendesk`
  const password = window.btoa(`${accountId}:${userId}`)

  return new Promise((resolve, reject) => {
    auth.signInWithEmailAndPassword(email, password)
      .then(data => {
        resolve(data.user)
      })
      .catch(error => {
        if (error.code === 'auth/user-not-found') {
          Auth.register(email, password)
            .then(user => resolve(user))
            .catch(error => reject(error))
        } else {
          reject(error)
        }
      })
  })
}

Auth.status = () => {
  return new Observable(observer => {
    auth.onAuthStateChanged(user => {
      observer.next(user)
    })
  })
}

Auth.register = (email, password) => {
  return auth.createUserWithEmailAndPassword(email, password)
    .then(data => {
      return data.user
    })
    .catch(error => {
      throw error
    })
}

Auth.logout = auth.signOut()

export default Auth
