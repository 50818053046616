import React from 'react'
import { Grid, Row, Col } from '@zendeskgarden/react-grid'
import { Tag } from '@zendeskgarden/react-tags'
import moment from 'moment'
import Avatar from './Avatar'
import Client from '../providers/Client'

const presenceConfig = {
  offline: 'Last seen',
  away: 'Away since',
  active: 'Active since',
  busy: 'Busy since'
}

const activityConfig = {
  view: {
    text: 'Viewing',
    color: 'lightgreen'
  },
  update: {
    text: 'Updating',
    color: 'orange'
  },
  create: {
    text: 'Creating',
    color: 'yellow'
  }
}

function UserItem (props) {
  return (
    <Grid gutters={false}>
      <Row>
        <Col size={3} style={{ textAlign: 'center' }}>
          <Avatar status={props.user.status} img={props.user.photo} text={getInitials(props.user.name)} />
        </Col>
        <Col size={9}>
          <Grid gutters={false}>
            <Col style={{ paddingRight: '10px' }}>
              <Row>
                <div className='u-1/1 u-semibold'>{props.user.name}</div>
              </Row>
              <Row style={{ paddingTop: '4px' }}>
                <div className='u-fs-sm u-1/1'>
                  {getPresence(props.user)}
                </div>
              </Row>
            </Col>
          </Grid>
        </Col>
      </Row>
    </Grid>
  )
}

function getPresence (user) {
  const activity = user.lastActivity || null
  const presence = user.lastPresence || null
  if (user.status !== 'offline' && activity && !activity.endTime) {
    return (
      <Tag hue={activityConfig[activity.type].color}>
        <span style={activity.referenceId ? { cursor: 'pointer' } : null} className='u-truncate' onClick={() => openObject(activity.object, activity.referenceId)}>{getActivityText(activity)}</span>
      </Tag>
    )
  } else if (presence) {
    return getPresenceText(presence)
  } else {
    return '...'
  }
}

function getActivityText (activity) {
  return `${activityConfig[activity.type].text} ${activity.object} ${activity.referenceId ? `#${activity.referenceId}` : ''}`
}

function getPresenceText (presence) {
  return `${presenceConfig[presence.status]} ${moment(presence.startTime).calendar()}`
}

// helpers
function openObject (object, id) {
  if (id) {
    Client.invoke('routeTo', object, id)
  }
}

function getInitials (name) {
  const nameArray = name.split(' ')
  const firstName = nameArray[0].split('')
  const lastName = nameArray[nameArray.length - 1].split('')
  if (nameArray.length > 1) {
    return firstName[0].toUpperCase() + lastName[0].toUpperCase()
  } else {
    return firstName[0].toUpperCase()
  }
}

export default UserItem
