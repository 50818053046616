import fb from './Firebase'
import firebase from 'firebase'
import { Observable } from 'rxjs'
import Presence from './Presence'

const Connection = {}

Connection.list = async (user) => {
  return fb.database().ref(`/connections/${user.accountId}/${user.id}`).once('value')
}

Connection.establish = async (user, context) => {
  const statusServer = fb.database().ref('.info/connected')
  const connectionRef = fb.database().ref(`/connections/${user.accountId}/${user.id}/${context.instanceGuid}`)

  const connectionData = {
    connectionId: context.instanceGuid,
    userId: user.id,
    accountId: user.accountId,
    timeStamp: firebase.database.ServerValue.TIMESTAMP
  }

  return new Observable(observer => {
    statusServer.on('value', (snapshot) => {
      if (snapshot.val() === true) {
        return connectionRef.onDisconnect().remove()
          .then(() => {
            connectionRef.set(connectionData)
          })
          .catch(error => { throw error })
      } else {
        const presence = new Presence(user.accountId, user.id, 'offline', 'local')
        observer.next(presence)
      }
    })
  })
}

export default Connection
