import React, { Component } from 'react'
import { Grid, Row, Col } from '@zendeskgarden/react-grid'
import UserPresence from '../components/UserPresence'
import User from '../providers/User'
import Users from '../providers/Users'
import Session from '../providers/Session'
import { getData, getContext } from '../providers/Client'
import { combineLatest } from 'rxjs'
import Auth from '../providers/Auth'
import Loading from '../components/Loading'

export class Sidebar extends Component {
  async componentDidMount () {
    await Auth.login()

    const context = await getContext()
    this.setState({ context: context })
    this.loadLocationData(context)

    const activity$ = Session.start(context)
    const user$ = User.observe()
    Promise.all([activity$, user$])
      .then((promises) => {
        combineLatest(promises[0], promises[1])
          .subscribe(([activity, user]) => {
            const currentUser = user
            if (activity && currentUser) {
              currentUser.lastActivity = activity
            }
            this.setState({ currentUser: currentUser })
          })
      })

    Users.presence(context)
      .then(usersPresence$ => {
        if (usersPresence$) {
          usersPresence$.subscribe(users => {
            if (users) {
              this.setState({ currentUsers: users })
            }
          })
        }
      })
  }

  async loadLocationData (context) {
    const location = context.location

    if (location === 'ticket_sidebar') {
      const data = {
        object: 'ticket',
        id: context.ticketId,
        title: `Ticket #${context.ticketId}`
      }
      this.setState({ location: data })
    } else if (location === 'new_ticket_sidebar') {
      const data = {
        object: 'ticket',
        id: null,
        title: 'New Ticket'
      }
      this.setState({ location: data })
    } else if (location === 'user_sidebar') {
      const data = {
        object: 'user',
        id: context.userId,
        title: await getData('user.name')
      }
      this.setState({ location: data })
    } else if (location === 'organization_sidebar') {
      const data = {
        object: 'organization',
        id: context.organizationId,
        title: await getData('organization.name')
      }
      this.setState({ location: data })
    }
  }

  getUserPresences () {
    if (this.state.currentUsers && this.state.currentUsers[0]) {
      return this.state.currentUsers.map(user => (
        <Row key={user.id}>
          <Col className='u-mb-sm'>
            <UserPresence user={user} />
          </Col>
        </Row>
      ))
    } else {
      return null
    }
  }

  render () {
    if (this.state && this.state.currentUser) {
      return (
        <Grid gutters={false}>
          <Row>
            <Col className='u-ta-center u-mb u-mt-sm'>
              <div className='u-fs-lg u-semibold'>{this.state.location.title}</div>
            </Col>
          </Row>
          <Row key={this.state.currentUser.id}>
            <Col className='u-mb-sm'>
              <UserPresence user={this.state.currentUser} />
            </Col>
          </Row>
          {this.getUserPresences()}
        </Grid>
      )
    } else {
      return (<Loading />)
    }
  }
}

export default Sidebar
