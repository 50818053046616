import React, { Component } from 'react'
import { Tag } from '@zendeskgarden/react-tags'
import Avatar from './Avatar'
import moment from 'moment'

const activity = {
  view: {
    display: 'Viewing',
    hue: 'lightgreen'
  },
  update: {
    display: 'Updating',
    hue: 'orange'
  },
  create: {
    display: 'Creating',
    hue: 'yellow'
  }
}

export class UserPresence extends Component {
  constructor (props) {
    super(props)
    this.props = props
    this.state = {
      timer: {
        display: '00:00',
        value: null
      },
      interval: null
    }
    this.countUp = this.countUp.bind(this)
  }

  componentDidMount () {
    const timer = getTimer(this.props.user.lastActivity)
    this.setState({ timer: timer })
    this.setState({ interval: setInterval(this.countUp, 1000) })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.user !== this.props.user) {
      const timer = getTimer(this.props.user.lastActivity)
      this.setState({ timer: timer })
    }
  }

  componentWillUnmount () {
    clearInterval(this.state.interval)
  }

  countUp () {
    const value = moment(this.state.timer.value)
    value.add(1, 'seconds')
    const display = formatTimer(value)

    this.setState({
      timer: {
        display: display,
        value: value
      }
    })
  }

  render () {
    if (this.props && this.props.user && this.props.user.lastActivity) {
      return (
        <div className='u-display-flex'>
          <div className='u-display-inline'>
            <Avatar size='medium' status={this.props.user.status} img={this.props.user.photo} text={getInitials(this.props.user.name)} />
          </div>
          <div className='u-1/1 u-semibold u-display-inline u-ml-xs u-truncate u-pt-xs'>{this.props.user.name}</div>
          <div className='u-display-inline-flex u-ta-right u-pt-xs'>
            <Tag hue={activity[this.props.user.lastActivity.type].hue}>{activity[this.props.user.lastActivity.type].display} {this.state.timer.display}</Tag>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

function getInitials (name) {
  const nameArray = name.split(' ')
  const firstName = nameArray[0].split('')
  const lastName = nameArray[nameArray.length - 1].split('')
  if (nameArray.length > 1) {
    return firstName[0].toUpperCase() + lastName[0].toUpperCase()
  } else {
    return firstName[0].toUpperCase()
  }
}

function getTimer (activity) {
  const start = moment(activity.startTime)
  const now = moment(new Date().toISOString())
  const diff = now.diff(start, 'seconds')
  const timerValue = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  timerValue.add(diff, 'seconds')
  const display = formatTimer(timerValue)
  const timer = {
    display: display,
    value: timerValue
  }
  // console.log('timer', activity.startTime, now.toISOString(), diff, timer)
  return timer
}

function formatTimer (value) {
  const hour = value.hour()
  let display = value.format('mm:ss')
  if (hour > 0) {
    display = value.format('h:mm:ss')
  }
  return display
}

export default UserPresence
