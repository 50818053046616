import { useEffect } from 'react'
import Client, { getData } from '../providers/Client'
import Account from '../providers/Account'
import User from '../providers/User'
import Auth from '../providers/Auth'

function Background () {
  useEffect(() => {
    Auth.login()
      .then(getAccount)
      .catch(error => {
        Client.invoke('notify', `Presence App: ${error.message}`, 'error', 10000)
      })
  })

  return (null)
}

async function getAccount () {
  const account = await Account.get()

  if (!account) {
    Client.invoke('notify', 'Setting up your Presence account.', 'notice', 10000)
    Account.create().then(getUser)
  } else if (!account.active) {
    Client.invoke('notify', 'Presence is no longer active. Please contact help@getassist.co.', 'error', 10000)
  } else {
    getUser()
  }
}

async function getUser () {
  const user = await User.get()
  if (!user) {
    User.create().then(loadTopBar)
  } else {
    loadTopBar()
  }
}

async function loadTopBar () {
  const instances = await getData('instances')
  for (const instance in instances) {
    if (instances[instance].location === 'top_bar') {
      Client.instance(instance).invoke('preloadPane')
    }
  }
}

export default Background
