import React from 'react'
import { Grid, Row, Col } from '@zendeskgarden/react-grid'
import StatusSelect from './StatusSelect'
import Avatar from './Avatar'

function AppHeader (props) {
  return (
    <div className='AppHeader'>
      <Grid gutters={false}>
        <Row>
          <Col size={3} style={{ textAlign: 'center', marginTop: '12px' }}>
            <Avatar status={props.user.status} img={props.user.photo} size='large' />
          </Col>
          <Col size={9}>
            <Grid gutters={false}>
              <Col style={{ paddingRight: '10px' }}>
                <Row>
                  <div className='u-pb-xs u-pt-xs u-1/1 u-semibold'>{props.user.name}</div>
                </Row>
                <Row>
                  <StatusSelect user={props.user} />
                </Row>
              </Col>
            </Grid>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default AppHeader
